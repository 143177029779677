module.exports = {
  "color": {
    "palette": {
      "white": "#ffffff",
      "whiteTransparent": {
        "20": "rgba(255, 255, 255, 0.2)",
        "30": "rgba(255, 255, 255, 0.3)",
        "40": "rgba(255, 255, 255, 0.4)",
        "50": "rgba(255, 255, 255, 0.5)",
        "80": "rgba(255, 255, 255, 0.8)",
        "90": "rgba(255, 255, 255, 0.9)"
      },
      "black": "#000000",
      "transparent": "rgba(0, 0, 0, 0)",
      "primaryblue": {
        "10": "#e5eaf2",
        "20": "#a5c0f2",
        "30": "#6f9bed",
        "40": "#457de5",
        "50": "#255fcc",
        "60": "#063b9e",
        "70": "#002d80",
        "80": "#002466",
        "90": "#00205b",
        "100": "#001b4d"
      },
      "secondaryblue": {
        "10": "#e6eff5",
        "20": "#a3d4f0",
        "30": "#6cbff0",
        "40": "#2e9fe0",
        "50": "#0b78b8",
        "60": "#005587",
        "70": "#00446c",
        "80": "#003351",
        "90": "#002236",
        "100": "#00111b"
      },
      "coolgrey": {
        "10": "#eff1f4",
        "20": "#e0e3e9",
        "30": "#c1c7d3",
        "40": "#a2acbd",
        "50": "#8390a7",
        "60": "#647592",
        "70": "#505d74",
        "80": "#3c4657",
        "90": "#282e3a",
        "100": "#14171d"
      },
      "warmgrey": {
        "10": "#f7f9fa",
        "20": "#f0f4f6",
        "30": "#e2e9ed",
        "40": "#d3dee4",
        "50": "#c5d3db",
        "60": "#b7c9d3",
        "70": "#92a0a8",
        "80": "#6d787e",
        "90": "#495054",
        "100": "#24282a"
      },
      "green": {
        "10": "#e5f7f1",
        "20": "#a5f2d8",
        "30": "#67ebbf",
        "40": "#2ed19b",
        "50": "#03b075",
        "60": "#08875b",
        "70": "#097350",
        "80": "#07593e",
        "90": "#064530",
        "100": "#043323"
      },
      "yellow": {
        "10": "#fef8e5",
        "20": "#fef1cc",
        "30": "#fde499",
        "40": "#fcd766",
        "50": "#fbca32",
        "60": "#fabd00",
        "70": "#d6a100",
        "80": "#9e7700",
        "90": "#644b00",
        "100": "#322500"
      },
      "red": {
        "10": "#fce5e9",
        "20": "#f9ccd4",
        "30": "#f499aa",
        "40": "#ee667f",
        "50": "#e93255",
        "60": "#e4002b",
        "70": "#b60022",
        "80": "#880019",
        "90": "#5b0011",
        "100": "#2d0008"
      },
      "orange": {
        "10": "#fff1e5",
        "20": "#ffe3cc",
        "30": "#ffc899",
        "40": "#ffad66",
        "50": "#ff9232",
        "60": "#ff7700",
        "70": "#bd5800",
        "80": "#994700",
        "90": "#662f00",
        "100": "#331700"
      },
      "limegreen": {
        "10": "#f2f8e5",
        "20": "#e6f1cc",
        "30": "#cde499",
        "40": "#b5d766",
        "50": "#9cca32",
        "60": "#84bd00",
        "70": "#699700",
        "80": "#4f7100",
        "90": "#344b00",
        "100": "#1a2500"
      },
      "limeyellow": {
        "10": "#fcfeb5",
        "20": "#f9f8cc",
        "30": "#f3f299",
        "40": "#edec66",
        "50": "#e7e632",
        "60": "#e1e000",
        "70": "#b4b300",
        "80": "#878600",
        "90": "#5a5900",
        "100": "#2d2c00"
      },
      "pink": {
        "10": "#fbe7f2",
        "20": "#f7d0e6",
        "30": "#f0a2cd",
        "40": "#e874b5",
        "50": "#e1469c",
        "60": "#da1884",
        "70": "#ae1369",
        "80": "#820e4f",
        "90": "#570934",
        "100": "#2b041a"
      },
      "purple": {
        "10": "#f6e7f3",
        "20": "#edd0e8",
        "30": "#dba2d2",
        "40": "#c974bc",
        "50": "#b746a6",
        "60": "#a51890",
        "70": "#841373",
        "80": "#630e56",
        "90": "#420939",
        "100": "#21041c"
      }
    },
    "brand": {
      "lightest": "#a5c0f2",
      "lighter": "#255fcc",
      "light": "#063b9e",
      "normal": "#002d80",
      "dark": "#002466",
      "darker": "#00205b",
      "darkest": "#001b4d",
      "secondary": "#0b78b8"
    },
    "theme": {
      "border": {
        "interactive": "#063b9e",
        "tab": "#063b9e",
        "focus": {
          "onWhite": "#255fcc",
          "onDark": "#ffffff"
        },
        "highlight": {
          "onWhite": "#2e9fe0",
          "onDark": "#2e9fe0"
        }
      },
      "background": {
        "inverse": "#00205b",
        "backdrop": "#001b4d",
        "interactive": {
          "small": "#063b9e"
        }
      },
      "text": {
        "highlight": {
          "default": "#063b9e",
          "hover": "#002466",
          "active": "#00205b",
          "disabled": "#a5c0f2"
        },
        "interactive": {
          "onWhite": "#063b9e",
          "onDark": "#063b9e"
        },
        "emphasis": "#001b4d"
      }
    },
    "background": {
      "primary": "#ffffff",
      "inverse": "#00205b",
      "backdrop": "#001b4d",
      "light1": "#eff1f4",
      "light2": "#f7f9fa",
      "light3": "#f0f4f6",
      "dark1": "#00205b",
      "dark2": "#0b78b8",
      "dark3": "#495054",
      "feedback": {
        "info": "#255fcc",
        "error": "#e4002b",
        "warning": "#fbca32",
        "success": "#08875b"
      },
      "interactive": {
        "primary": {
          "small": "#063b9e",
          "default": "#002d80",
          "hover": "#002466",
          "active": "#00205b"
        },
        "secondary": {
          "default": "#ffffff",
          "hover": "#f0f4f6",
          "active": "#e2e9ed",
          "contrastText": "#063b9e"
        },
        "ghost": {
          "default": "rgba(0, 0, 0, 0)",
          "hover": "#f0f4f6",
          "active": "#e2e9ed",
          "contrastText": "#063b9e"
        },
        "success": {
          "default": "#08875b",
          "hover": "#097350",
          "active": "#07593e"
        },
        "warning": {
          "default": "#fbca32",
          "hover": "#fabd00",
          "active": "#d6a100"
        },
        "error": {
          "default": "#e4002b",
          "hover": "#b60022",
          "active": "#880019"
        }
      }
    },
    "border": {
      "interactive": {
        "onWhite": "#063b9e",
        "onDark": "#ffffff"
      },
      "focus": {
        "onWhite": "#255fcc",
        "onDark": "#ffffff"
      },
      "control": {
        "onWhite": "#a2acbd",
        "onDark": "#ffffff"
      },
      "tab": {
        "onWhite": "#063b9e",
        "onDark": "#ffffff"
      },
      "highlight": {
        "onWhite": "#2e9fe0",
        "onDark": "#2e9fe0"
      },
      "divider": {
        "onWhite": "#c1c7d3",
        "onDark": "rgba(255, 255, 255, 0.5)"
      },
      "feedback": {
        "success": {
          "onWhite": "#08875b",
          "onDark": "#08875b"
        },
        "error": {
          "onWhite": "#e93255",
          "onDark": "#e93255"
        }
      }
    },
    "feedback": {
      "info": {
        "lightest": "#a3d4f0",
        "lighter": "#0b78b8",
        "light": "#005587",
        "normal": "#255fcc",
        "dark": "#063b9e",
        "darker": "#002d80",
        "darkest": "#002466"
      },
      "success": {
        "lightest": "#a5f2d8",
        "lighter": "#03b075",
        "light": "#08875b",
        "normal": "#097350",
        "dark": "#07593e",
        "darker": "#064530",
        "darkest": "#043323"
      },
      "warning": {
        "lightest": "#ffe3cc",
        "lighter": "#ff9232",
        "light": "#ff7700",
        "normal": "#bd5800",
        "dark": "#994700",
        "darker": "#662f00",
        "darkest": "#331700"
      },
      "error": {
        "lightest": "#f9ccd4",
        "lighter": "#e93255",
        "light": "#e4002b",
        "normal": "#b60022",
        "dark": "#880019",
        "darker": "#5b0011",
        "darkest": "#2d0008"
      }
    },
    "legacy": {
      "gray": {
        "100": "#d9d9db",
        "200": "#b3b4b7",
        "300": "#8f9295",
        "400": "#6d7073",
        "500": "#4c5054",
        "600": "#2e3237",
        "700": "#14161a"
      },
      "grayscale": {
        "100": "#eff1f4",
        "200": "#e0e3e9",
        "300": "#c1c7d3",
        "400": "#9aa5b8",
        "500": "#8390a7",
        "600": "#647592",
        "700": "#505d73"
      }
    },
    "text": {
      "primary": {
        "onWhite": "#282e3a",
        "onDark": "#ffffff"
      },
      "secondary": {
        "onWhite": "#505d74",
        "onDark": "#ffffff"
      },
      "tertiary": {
        "onWhite": "#647592",
        "onDark": "#ffffff"
      },
      "disabled": "#c1c7d3",
      "emphasis": "#001b4d",
      "interactive": {
        "onWhite": "#063b9e",
        "onDark": "#063b9e"
      },
      "highlight": {
        "onWhite": {
          "default": "#063b9e",
          "accent": "#002d80",
          "hover": "#002466",
          "active": "#00205b",
          "disabled": "#a5c0f2",
          "secondary": "#b746a6"
        },
        "onDark": {
          "default": "#ffffff",
          "accent": "#ffffff",
          "hover": "#e0e3e9",
          "active": "#c1c7d3",
          "disabled": "rgba(255, 255, 255, 0.4)",
          "secondary": "#c974bc"
        }
      },
      "feedback": {
        "error": "#e4002b",
        "success": "#08875b"
      }
    }
  },
  "elevation": {
    "level1": "0 1px 1px 0 rgba(0,0,0,0.14)",
    "level2": "0 1px 4px 0 rgba(0,0,0,0.2)",
    "level3": "0 2px 8px 0 rgba(0,0,0,0.14)",
    "level4": "0 2px 8px 0 rgba(0,0,0,0.2)",
    "level5": "0 8px 16px 2px rgba(0,0,0,0.2)",
    "zindex": {
      "drawer": 1000,
      "header": 1100,
      "appbar": 1100,
      "banner": 1200,
      "modal": 1300,
      "dropdown": 1400,
      "popover": 1500,
      "tooltip": 1600
    }
  },
  "font": {
    "family": "Roboto, Helvetica Neue, Helvetica, Arial, sans-serif",
    "lineheight": {
      "xxsmall": "1rem",
      "xsmall": "1.125rem",
      "small": "1.25rem",
      "medium": "1.5rem",
      "large": "1.75rem",
      "xlarge": "1.875rem",
      "heading-6": "2rem",
      "heading-5": "2.5rem",
      "heading-4": "3rem",
      "heading-3": "3.5rem",
      "heading-2": "4.5rem",
      "heading-1": "5rem"
    },
    "size": {
      "xsmall": "0.75rem",
      "small": "0.875rem",
      "medium": "1rem",
      "large": "1.125rem",
      "xlarge": "1.25rem",
      "heading-6": "1.25rem",
      "heading-5": "1.5rem",
      "heading-4": "2rem",
      "heading-3": "2.25rem",
      "heading-2": "3rem",
      "heading-1": "3.5rem"
    },
    "weight": {
      "regular": 400,
      "medium": 500,
      "bold": 700
    }
  },
  "responsive": {
    "breakpoint": {
      "xxs": 320,
      "xs": 480,
      "sm": 768,
      "md": 960,
      "lg": 1200,
      "xl": 1440,
      "xxl": 1920
    },
    "columns": {
      "2": 2,
      "4": 4,
      "6": 6,
      "12": 12
    },
    "contentarea": {
      "xxs": 304,
      "xs": 432,
      "sm": 736,
      "md": 928,
      "lg": 1120,
      "xl": 1408,
      "xxl": 1760
    },
    "gutter": {
      "s": 24,
      "m": 32
    }
  },
  "size": {
    "spacing": {
      "2": "0.125rem",
      "4": "0.25rem",
      "8": "0.5rem",
      "12": "0.75rem",
      "16": "1rem",
      "20": "1.25rem",
      "24": "1.5rem",
      "32": "2rem",
      "40": "2.5rem",
      "48": "3rem",
      "56": "3.5rem",
      "64": "4rem",
      "80": "5rem",
      "112": "7rem",
      "inset": {
        "quarter-x": "0.125rem",
        "half-x": "0.25rem",
        "1-x": "0.5rem",
        "1-and-a-half-x": "0.75rem",
        "2-x": "1rem",
        "3-x": "1.5rem",
        "4-x": "2rem",
        "5-x": "2.5rem",
        "6-x": "3rem",
        "7-x": "3.5rem",
        "8-x": "4rem"
      }
    }
  }
};